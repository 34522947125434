<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="equipTable"
        title="관련 설비 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="equipmentCd"
        selection="multiple"
        @linkClick="linkClick1"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable && !disabled" :showLoading="false" label="등록" icon="add" @btnClicked="addEquipment" />
            <c-btn v-if="editable && !disabled && grid.data.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="removeEquipment" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <c-table
        ref="chemTable"
        title="관련 화학자재 목록"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :isExcelDown="false"
        :editable="editable && !disabled"
        rowKey="materialCd"
        selection="multiple"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="등록" :showLoading="false"  icon="add" @btnClicked="addChem" />
            <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeChem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>


<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'traning-action-relation',
  props: {
    emerActPlanDocu: {
      type: Object,
      default: () => ({
        plantCd: '',
        sopEmergencyPlanId: '',
        emergencyMaterialModels: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호',
            style: 'width:100px',
            align: 'center',
            type: 'link',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'left',
            style: 'width:300px',
            sortable: false
          },
        ],
        data: [],
        height: '380px'
      },
      grid2: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            type: 'link',
            sortable: true
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'casNo',
            align: 'center',
            sortable: true
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '380px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'grid.data'() {
      this.emerActPlanDocu.emergencyMaterialModels = (this.$_.concat(this.grid.data, this.grid2.data));
    },
    'grid2.data'() {
      this.emerActPlanDocu.emergencyMaterialModels = (this.$_.concat(this.grid.data, this.grid2.data));
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.eap.relateAccident.insert.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$_.forEach(this.emerActPlanDocu.emergencyMaterialModels, _item => {
        if (_item.equipmentMaterialTypeCd === 'EQUIP') {
          this.grid.data.push(_item) // 설비
        } else {
          this.grid2.data.push(_item) // 화학자재
        }
      })
    },
    /* eslint-disable no-unused-vars */ 
    addEquipment() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '관련설비 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveItems = [];
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            equipmentCd: _item.equipmentCd,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'EQUIP',
              equipmentNo: _item.equipmentNo,
              managementDepts: _item.managementDepts,
              equipmentTypeName: _item.equipmentTypeName,
              checkDeptCd: _item.managementDepts,
              equipmentName: _item.equipmentName,
              equipmentCd: _item.equipmentCd,
              relatedLawsName: _item.relatedLawsName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
            saveItems.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'EQUIP',
              managementDepts: _item.managementDepts,
              equipmentTypeName: _item.equipmentTypeName,
              checkDeptCd: _item.managementDepts,
              equipmentName: _item.equipmentName,
              equipmentCd: _item.equipmentCd,
              relatedLawsName: _item.relatedLawsName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
        this.$http.url = transactionConfig.sop.eap.relateMaterial.insert.url;
        this.$http.type = 'POST';
        this.$http.param = saveItems;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
    removeEquipment() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.eap.relateMaterial.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request((_result) => {
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, { equipmentCd: item.equipmentCd })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addChem() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '관련 화학자재 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemPopup;
    },
    closeChemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveItems = [];
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid2.data, {
            mdmChemMaterialId: _item.mdmChemMaterialId,
          });
          if (index === -1) {
            this.grid2.data.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'CHEM',
              materialCd: _item.materialCd,
              mdmChemMaterialId: _item.mdmChemMaterialId,
              casNo: _item.casNo,
              materialName: _item.materialName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
            saveItems.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'CHEM',
              materialCd: _item.materialCd,
              mdmChemMaterialId: _item.mdmChemMaterialId,
              casNo: _item.casNo,
              materialName: _item.materialName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
        this.$http.url = transactionConfig.sop.eap.relateMaterial.insert.url;
        this.$http.type = 'POST';
        this.$http.param = saveItems;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
    removeChem() {
      let selectData = this.$refs['chemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?', 
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.eap.relateMaterial.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request((_result) => {
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item)
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    linkClick1(data) {
      this.popupOptions.title = '관련 설비 상세';
      this.popupOptions.param = 
      this.popupOptions.param = {
        equipmentCd: data ? data.equipmentCd : '',
        psmFlag: data ? data.psmFlag : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClick2(data) {
      this.popupOptions.title = '관련 화학물질 상세'; 
      this.popupOptions.param = 
      this.popupOptions.param = {
        mdmChemMaterialId: data ? data.mdmChemMaterialId : '',
        plantCd: data ? this.emerActPlanDocu.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>